import React from "react";
import Layout from "../components/layout/Layout";
import TextScroller from "../components/Wellness/TextScroller/TextScroller";
import Header from "../components/WellnessJoinNow/Header";
import { address } from "../utils/data";
import DownloadSection from "../components/WellnessJoinNow/DownloadSection";
import Enquire from "../components/WellnessJoinNow/Enquire";
import Title from "../components/WellnessJoinNow/Title";

function WellnessJoinNow() {
  return (
    <Layout>
      <div>
        <Header bottomText="pricing" bottomTextColor="#CADED5" />
        <TextScroller />
        <div className="pt-[60px] bg-[#282828] ">
          <div className="text-center">
            <Title title="Dickson, ACT" />
          </div>
          <p className="text-center text-[18px] text-[#CADED5] px-4">
            {address}
          </p>
          <section className="container bg-gray-900 relative lg:mt-[-70px] ">
            <DownloadSection />
          </section>
          <Enquire />
          <TextScroller />
        </div>
      </div>
    </Layout>
  );
}

export default WellnessJoinNow;
