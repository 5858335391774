import React from "react";
import wellnessBanner from "../../assets/images/wellness/WellnessBanner.jpg";
import GroundUpImage from "../../assets/images/wellness/GroundupImage.svg";

function Header({
  bottomText,
  bottomImage,
  middleText,
  bottomTextColor,
}: {
  bottomText?: string;
  bottomImage?: boolean;
  middleText?: string;
  bottomTextColor?: string;
}) {
  return (
    <div
      style={{
        backgroundImage: `url(${wellnessBanner})`,
      }}
      className="h-[688px] bg-cover bg-center relative flex items-center"
    >
      {middleText && (
        <h1
          className={`text-white text-3xl md:text-5xl xl:text-7xl font-bold absolute top-[35%] lg:-bottom-5 w-full text-center uppercase`}
        >
          {middleText}
        </h1>
      )}

      {bottomImage && (
        <div className="hidden md:block absolute bottom-[60px] left-1/2 transform -translate-x-1/2 w-[55%]">
          <img
            src={GroundUpImage}
            alt="Groundup image"
            className="w-full h-auto object-cover"
          />
        </div>
      )}

      {bottomText && (
        <h1
          className={`hidden md:block text-[#CADED5] text-3xl md:text-[85px] font-medium absolute bottom-0 lg:bottom-3 w-full text-center uppercase`}
        >
          {bottomText}
        </h1>
      )}
    </div>
  );
}

export default Header;
