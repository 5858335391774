import React from "react";

import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";
import VideoContainer from "../components/HomePage/VideoContainer";
import TextScroller from "../components/Wellness/TextScroller/TextScroller";
import WellnessStudio from "../components/Wellness/Pricing/WellnessStudio";
import GetInTouch from "../components/Wellness/Pricing/GetInTouch";

function WellnessPricing() {
  const { data } = useFetch({
    params: "home-page",
    populate:
      "introVideo,bannerMobile,bannerURL,step_1.background,step2.background,step3.background,step4.background",
  });
  return (
    <Layout>
      {data && (
        <div className="wellness-home">
          <VideoContainer data={data} />
          <TextScroller />
          <WellnessStudio />
          <GetInTouch />
          <TextScroller />
        </div>
      )}
    </Layout>
  );
}

export default WellnessPricing;
