import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";

function Layout(props: any) {
  const { page } = props;

  return (
    <div id="Layout" className="w-full relative">
      <Navbar page={page} />
      {props.children}
      <Footer page={page} />
    </div>
  );
}
export default Layout;
