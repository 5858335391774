import React from "react";

const WellnessStudio = () => {
  return (
    <div className="bg-[#141414] px-[11.5rem] py-[7.875rem] flex flex-col gap-[2rem]">
      <div className="flex flex-col gap-[2.2rem] items-center">
        <h1 className="text-[#CADED5] uppercase text-[2.375rem] font-[500] leading-[3.125rem]">
          select a studio from the list below
        </h1>
      </div>
      <div></div>
    </div>
  );
};

export default WellnessStudio;
