import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import Layout from "../components/layout/Layout";

function StudioDetail() {
  const widgetIds = {
    belconnen: "HQ988q2R4dwpf4S9AqwD",
    yarralumla: "JNpY6a12lMxbopNg5069",
    gungahlin: "3RHN7kvQbQ2KCeF1GYV5",
    alexandria: "HNsAGU0EawVuJwTOziF1",
    braddon: "9AmlqVTeL51ZouR1Tdg3",
    dickson: "r41WnscMBLEQpxOnsitR",
  };

  const location = useLocation();
  const [iframeId, setIframeId] = useState<string>("");
  const [clubName, setClubName] = useState<string>("");
  const [loading, setLoading] = useState(true); // Track when both are ready

  useEffect(() => {
    // Strip trailing slash if it exists
    const path = location.pathname.replace(/\/$/, "");

    const pathSegments = path.split("-");
    const lastSegment = pathSegments[pathSegments.length - 1];

    if (widgetIds[lastSegment]) {
      setIframeId(widgetIds[lastSegment]);
      setClubName(lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1));
      setLoading(false); // Set loading to false when both are ready
    } else {
      console.error("No matching widget ID found for path:", path);
    }
  }, [location.pathname]);

  if (loading) {
    return <p>Loading...</p>; // Optional loading indicator
  }

  return (
    <Layout>
      <div id="studios-form-lead" className="member-page-container">
        <div className="membership-header">
          <h2 className="h4">{clubName} Enquiry</h2>
        </div>
        <div className="iframe-container">
          <iframe
            src={`https://grow-api.hapana.com/widget/form/${iframeId}`}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            id={`inline-${iframeId}`}
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="[1.0] Website Enquiry Form"
            data-height="517"
            data-layout-iframe-id={`inline-${iframeId}`}
            data-form-id={iframeId}
            title="[1.0] Website Enquiry Form"
          ></iframe>
          <script src="https://grow-api.hapana.com/js/form_embed.js"></script>
        </div>
      </div>
    </Layout>
  );
}

export default StudioDetail;
