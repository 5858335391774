import React from 'react';
import { Link } from "gatsby";

function About(props) {
  const { data } = props

  return (
    <div id="about" className="home-about-container home">
      <div className="grid-container">
        <div className="grid-x grid-padding-x relative">
          <div className="cell medium-5 left-content">
            <div
              className="bg-image home-about-img"
              id="aiiolb-interchange"
              style={{ backgroundImage: `url(${data?.data?.attributes?.step_1?.background?.data?.attributes?.url})` }}
            ></div>
          </div>
          <div className="cell medium-7 right-content">
            <div className="text-container">
              <header>
                <h1 className="stencil">
                  <span className="pilates">{data?.data?.attributes?.step_1?.customMetadata?.[0]}</span>
                  <span className="yoga">{data?.data?.attributes?.step_1?.customMetadata?.[1]}</span>
                  <span className="barre">{data?.data?.attributes?.step_1?.customMetadata?.[2]}</span>
                </h1>
              </header>
              <div className="p1">
                <p
                  className="leading-[1.2em]"
                >
                  {data?.data?.attributes?.step_1?.description}
                </p>
                <Link
                  to="/about"
                  className="button transparent font-normal text-[20px]"
                >
                  About
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
