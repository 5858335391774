import React from "react";

import arrowDownWhite from "../../assets/svg/arrow-down-white.svg";
import ScrollToElm from "../../common/scrollEffect/ScrollToElm";
import { useLocation } from "@reach/router";
import wellnessBanner from "../../assets/svg/Wellness_Banner.svg";
import wellnessBannerMobile from "../../assets/svg/Wellness_Banner_Mobile.svg";
import facilitiesBanner from "../../assets/svg/Facilities_Banner.svg";
import pricingBanner from "../../assets/svg/Pricing_Banner.svg";
import contactBanner from "../../assets/svg/Wellness_Contact_Banner.svg";
import { Link } from "gatsby";

function VideoContainer(data) {
  const router = useLocation();
  const isWellness = router.pathname.replace(/\/$/, "") === "/wellness";

  const isWellnessFacilities =
    router.pathname.replace(/\/$/, "") === "/wellness-facilities";
  const isWellnessPricing =
    router.pathname.replace(/\/$/, "") === "/wellness-bookings";
  const isWellnessContact =
    router.pathname.replace(/\/$/, "") === "/wellness-contact";

  const bannerLink = data?.data?.data?.attributes?.bannerURL;

  const introVideoUrl =
    data?.data?.data?.attributes?.introVideo?.data?.attributes?.url;
  const bannerMobileUrl =
    data?.data?.data?.attributes?.bannerMobile?.data?.attributes?.url;

  const isVideo = introVideoUrl?.endsWith("mp4");
  const isVideoInMobile = bannerMobileUrl?.endsWith("mp4");

  return (
    <div id="banner-image" className="w-full my-0 relative">
      <Link to="/join-now">
        {/* Desktop Version */}
        <div className="md:flex hidden h-full mx-auto">
          {isVideo ? (
            <video
              autoPlay
              src={introVideoUrl}
              muted
              loop
              className="w-[100vw] object-cover overflow-hidden"
            ></video>
          ) : (
            <img
              src={
                isWellness
                  ? wellnessBanner
                  : isWellnessFacilities
                  ? facilitiesBanner
                  : isWellnessPricing
                  ? pricingBanner
                  : isWellnessContact
                  ? contactBanner
                  : introVideoUrl
              }
              className="w-[100vw] object-bottom overflow-hidden object-cover"
              alt="Banner"
            />
          )}
        </div>
      </Link>
      <div className="absolute bottom-0 hidden md:flex  justify-center w-full ">
        <h3 className="text-[#CADED5] text-[8rem] font-[500]">
          {" "}
          {isWellness ? "Wellness" : isWellnessPricing ? "Bookings" : ""}
        </h3>
      </div>

      {/* Mobile Version */}
      <Link to="/join-now">
        <div className="md:hidden block">
          {isVideoInMobile ? (
            <video
              autoPlay
              src={bannerMobileUrl}
              muted
              loop
              className="h-[100vh] w-[100vw] object-cover overflow-hidden"
            ></video>
          ) : (
            <img
              src={
                isWellness
                  ? wellnessBannerMobile
                  : isWellnessFacilities
                  ? facilitiesBanner
                  : isWellnessPricing
                  ? pricingBanner
                  : isWellnessContact
                  ? contactBanner
                  : bannerMobileUrl
              }
              className="h-full w-full object-cover object-center overflow-hidden"
              alt="Mobile Banner"
            />
          )}
        </div>
      </Link>
    </div>
  );
}

export default VideoContainer;
