import React from "react";

import Facebook_Mobile from "../../assets/images/wellness/facebookWellness.svg";
import Instagram_Mobile from "../../assets/images/wellness/instagramWellness.svg";
import TikTokIcon from "../../assets/images/wellness/tiktokWellness.svg";
import FacebookIconMobile from "../../assets/images/wellness/FacebookIconMobile.svg";
import InstagramIconMobile from "../../assets/images/wellness/InstagramIconMobile.svg";
import TikTokIconMobile from "../../assets/images/wellness/TikTokIconMobile.svg";
import Tile1 from "../../assets/images/wellness/followTheWellnessTile1.svg";
import Tile2 from "../../assets/images/wellness/followTheWellnessTile2.svg";
import Tile3 from "../../assets/images/wellness/followTheWellnessTile3.svg";
import Tile4 from "../../assets/images/wellness/followTheWellnessTile4.svg";

const FollowTheWellness: React.FC = () => {
  return (
    <>
      <div className="hidden md:flex flex-col gap-[3.75rem] pb-[12.50rem] px-[12.19rem] pt-[8.63rem] bg-[#141414]">
        <div className="flex justify-between items-center  align-bottom">
          <h2 className="font-[500] text-[2.38rem] wellness-title-text">
            FOLLOW THE WELLNESS
          </h2>
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61568281096611"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook_Mobile} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/groundup.wellness/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram_Mobile} alt="Instagram" />
            </a>
            <a
              href="https://www.tiktok.com/@groundup.wellness"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TikTokIcon} alt="TikTok" className="cursor-pointer" />
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-[1.81rem]">
          <div className="relative group">
            <img
              src={Tile1}
              alt="Wellness 1"
              className="object-cover w-full h-auto"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="text-center text-white">
                {/* <p className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <p>lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
              </div>
            </div>
          </div>
          <div className="relative group">
            <img
              src={Tile2}
              alt="Wellness 2"
              className="object-cover w-full h-auto"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="text-center text-white">
                {/* <p className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <p>lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
              </div>
            </div>
          </div>
          <div className="relative group">
            <img
              src={Tile3}
              alt="Wellness 3"
              className="object-cover w-full h-auto"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="text-center text-white">
                {/* <p className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <p>lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
              </div>
            </div>
          </div>
          <div className="relative group">
            <img
              src={Tile4}
              alt="Wellness 4"
              className="object-cover w-full h-auto"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="text-center text-white">
                {/* <p className="mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <p>lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden flex flex-col bg-[#ffffff] py-[3rem] px-[1.875rem] gap-[1.875rem]">
        <div className="flex flex-col gap-[0.5rem]">
          <h2 className="text-[#274240] font-[500] text-[1.563rem]">FOLLOW</h2>
          <h2 className="text-[#274240] font-[500] text-[1.563rem] ">
            THE WELLNESS
          </h2>
        </div>
        <div className="flex gap-[1.1rem]">
          <a
            href="https://www.facebook.com/profile.php?id=61568281096611"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Facebook_Mobile} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/groundup.wellness/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Instagram_Mobile} alt="Instagram" />
          </a>
          <img
            src={TikTokIconMobile}
            alt="Facebook"
            className=" cursor-pointer"
          />
        </div>
      </div>
    </>
  );
};

export default FollowTheWellness;
