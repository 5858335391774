import React, { useState } from "react";
import { useLocation } from "@reach/router";

const GetInTouchForm = () => {
  const router = useLocation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };
  return (
    <div className="bg-black py-[7.438rem] px-[35.813rem] flex flex-col items-center gap-[2rem]">
      <div className="flex flex-col items-center gap-[2.625rem]">
        <h2 className="text-[#CADED5] text-[2.375rem] font-[500] leading-[3.125rem]">
          GET IN TOUCH
        </h2>
        <p className="text-center font-[400] text-[1.5rem] text-white leading-[1.938rem]">
          Got a question or want to find out more? Just fill in your details
          below and one of our team will be in touch with you as soon as
          possible.
        </p>
        <form className="w-full max-w-lg " onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[0.563rem] mb-[0.438rem]">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="First Name*"
              className="getInTouchFull"
              required
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Last Name*"
              className="getInTouchFull"
              required
            />
          </div>
          <div className="mb-[0.438rem]">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address*"
              className="getInTouchFull"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-[0.563rem] mb-[0.438rem]">
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Phone number*"
              className="getInTouchFull"
              required
            />
            <select
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              className="getInTouchFull"
              style={{ color: "#caded5" }}
            >
              <option className="getInTouchFull" value="">
                Location
              </option>
              <option value="Location 1">Location 1</option>
              <option value="Location 2">Location 2</option>
              <option value="Location 3">Location 3</option>
            </select>
          </div>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="How can we help?"
            className="getInTouchFullTextarea"
          ></textarea>
          <div className="flex items-centered justify-center">
            <button
              type="submit"
              style={{ borderRadius: "100px" }}
              className="px-[22px] py-[8px] mt-[2rem] bg-[#CADED5] text-black rounded-full font-[400] capitalize text-[1rem]"
            >
              Enquire Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GetInTouchForm;
