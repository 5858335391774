import React from "react";
import Title from "../../WellnessJoinNow/Title";
import { offerings } from "../../../utils/data";

const OurOfferings: React.FC = () => {
  return (
    <section className=" bg-[#274240]">
      <div className="container py-20">
        <Title title="Our Offerings" />
        {/* <div className="hidden md:grid grid-cols-4 gap-[47px]">
        {offerings.map((offering, index) => (
          <div key={index} className="">
            <img
              src={offering.image}
              alt={offering.title}
              className="w-full h-auto  mb-[38px] object-cover"
            />
            <p className="text-left text-[24px] text-base wellness-title-text font-[400] leading-[32px]">
              {offering.title}
            </p>
          </div>
        ))}
      </div> */}
        {/* <div className="md:hidden flex flex-col gap-[26px]">
        {offerings.map((offering, index) => (
          <div key={index} className="">
            <img
              src={offering.mobileImage}
              alt={offering.title}
              className="w-full h-auto object-cover"
            />
          </div>
        ))}
      </div> */}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[20px] md:gap-[25px]">
          {offerings.map((offering, index) => (
            <div>
              <div
                key={index}
                className="relative group w-full h-auto overflow-hidden "
              >
                {/* Image */}
                <img
                  src={offering.image}
                  alt={offering.title}
                  className="w-full h-auto object-cover"
                />

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-black/50 opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300">
                  {/* Upper Text */}
                  <span className="absolute top-0 right-0 text-white text-[28px] sm:text-[30px] md:text-[32px] font-medium uppercase opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300 pointer-events-none -mt-[10px]">
                    {offering.upperText}
                  </span>

                  {/* Middle Text */}
                  <span className="absolute inset-0 flex items-center justify-center text-white text-xs text-center px-4 opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                    {offering.middleText}
                  </span>

                  {/* Lower Text */}
                  <span className="absolute bottom-0 left-0 text-white text-[28px] sm:text-[30px] -mb-[10px] opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300">
                    {offering.lowerText}
                  </span>
                </div>
              </div>

              {/* Title */}
              <p className="text-left text-[18px] sm:text-[20px] md:text-[24px] text-base wellness-title-text font-[400] leading-[24px] sm:leading-[28px] md:leading-[32px] mt-[20px] md:mt-[38px]">
                {offering.title}
              </p>
            </div>
          ))}
        </div>

        {/* <Link className="md:hidden" to="/">
        <button className="w-[80%] mt-[3.938rem] py-[10px] px-auto bg-[#CADED5] text-[#274240] font-[400] text-[1rem] rounded-full">
          Book Now
        </button>
      </Link> */}
      </div>
    </section>
  );
};

export default OurOfferings;
