import React from "react";
import { Link } from "gatsby";
import Title from "./Title";

function Enquire() {
  return (
    <section className="bg-black py-20 ">
      <div className="max-w-[90%] mx-auto">
        <div className="text-center">
          <Title title="get in touch" />
        </div>
        <p className="text-white text-center max-w-xl mx-auto">
          Got a question or want to find out more? Just fill in your details
          below and one of our team will be in touch with you as soon as
          possible.
        </p>
        <div className="btn-container text-center mt-10">
          <Link
            to="/sale-lead-form-dickson`"
            className="px-4 py-2 bg-[#CADED5] hover:bg-[#274240] text-[#274240] hover:text-[#CADED5] rounded-full font-[400] capitalize text-4"
          >
            Enquire now
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Enquire;
