import ice_bath_FA from "../assets/images/wellness/ice_bath_FA.jpeg";
import infrared_sauna_FA from "../assets/images/wellness/infrared_sauna_FA.jpeg";
import redLight_FA from "../assets/images/wellness/red-light_FA.jpeg";
import traditional_sauna_FA from "../assets/images/wellness/traditional_sauna_FA.jpeg";

export const address =
  "South Building, DKSN 2.0 Level 2, 23 Challis St, Dickson ACT 2602";

export const navWellnessLinks1 = [
  { id: 1, href: "/about", text: "about" },
  { id: 1, href: "/wellness#download", text: "join now" },
  { id: 3, href: "/wellness", text: "wellness" },
  { id: 4, href: "/", text: "movement" },
];

export const navLinks = [
  { id: 1, href: "/about", text: "about" },
  { id: 2, href: "/studios", text: "studios" },
  { id: 3, href: "/classes", text: "classes" },
  { id: 4, href: "/timetable", text: "timetable" },
];

export const offerings = [
  {
    title: "Traditional Sauna",
    image: traditional_sauna_FA,
    mobileImage: traditional_sauna_FA,
    upperText: "SAU",
    middleText:
      "Traditional saunas use high heat and low humidity to create a hot and dry environment. The heat from the sauna helps to relax muscles, relieve tension, and reduce stress. The calming environment also promotes mental relaxation. The experience can be intensified by pouring water over heated rocks to produce steam, temporarily raising humidity levels.",
    lowerText: "NA",
  },
  {
    title: "Infrared Sauna",
    image: infrared_sauna_FA,
    mobileImage: infrared_sauna_FA,
    upperText: "INFRA",
    middleText:
      "Infrared saunas use infrared heaters to emit infrared light, which is absorbed directly by the skin, heating the body from the inside out. Infrared heat increases blood flow and improves circulation, which can enhance cardiovascular health, support muscle recovery, and promote overall wellness.",
    lowerText: "RED",
  },
  {
    title: "Ice Baths",
    image: ice_bath_FA,
    mobileImage: ice_bath_FA,
    upperText: "ICE",
    middleText:
      "Experience the powerful anti-inflammatory benefits of cold-water immersion. The cold temperature constricts blood vessels and decreases metabolic activity, which limits swelling and tissue breakdown. As your body warms up post-bath, blood flow increases, flushing out metabolic waste and speeding up the healing process, to help reduce muscle soreness, boost circulation, improve your immune system and even aid with sleep.",
    lowerText: "BATH",
  },
  {
    title: "Red Light Therapy",
    image: redLight_FA,
    mobileImage: redLight_FA,
    upperText: "RED",
    middleText:
      "Red light therapy beds utilize low-level wavelengths of red and near-infrared light to penetrate the skin's surface, promoting cellular function and regeneration. This non-invasive treatment stimulates various health benefits without causing thermal damage to the skin. The beds are designed for full-body exposure, allowing users to experience the therapeutic effects in a comfortable and relaxing environment.",
    lowerText: "LIGHT",
  },
];
