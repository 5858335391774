import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import { useLocation } from "@reach/router";

import Layout from "../components/layout/Layout";

function StudioDetail() {
  const form = useRef();
  const params = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    lname: "",
    phoneHome: "",
    email: "",
    loc: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.entries(userInfo).filter(
      ([key, value]) => value === ""
    );
    if (emptyFields.length > 0) {
      alert(`Please complete all info`);
    } else {
      await emailjs.sendForm(
        "service_uyai7ia",
        "template_phmirqe",
        form?.current,
        "qPnWu5SByn-ikFIuO"
      );
      // await axios.post('https://api.vivaleisure.com.au/api/hub/gu_gymsales/', userInfo)
      //     .then(res => { }).catch(err => console.log(err));
      setLoading(true);
    }
  };

  return (
    <Layout>
      <div
        id="studios-form-lead"
        className="member-page-container bg-[#EBEADF]"
      >
        <div className="membership-header">
          <h2 className="h4">FREE CLASS</h2>
        </div>
        <div className="flex w-[100%] h-[100%] mx-auto  mt-[65px] rounded-t px-[25px] py-[35px] border free-class-container mx-auto">
          <iframe
            src="https://grow-api.hapana.com/widget/form/rSVo2O3xzdBiuKOEh907"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "0px",
              minWidth: "400px",
            }}
            id="inline-rSVo2O3xzdBiuKOEh907"
            data-layout="{'id':'INLINE'}"
            data-trigger-type="alwaysShow"
            data-trigger-value=""
            data-activation-type="alwaysActivated"
            data-activation-value=""
            data-deactivation-type="neverDeactivate"
            data-deactivation-value=""
            data-form-name="Website | Free Trial Redemption Form"
            data-height="599"
            data-layout-iframe-id="inline-rSVo2O3xzdBiuKOEh907"
            data-form-id="rSVo2O3xzdBiuKOEh907"
            title="Website | Free Trial Redemption Form"
          ></iframe>
          <script src="https://grow-api.hapana.com/js/form_embed.js"></script>
        </div>
      </div>
    </Layout>
  );
}

export default StudioDetail;
