import React from "react";
import { Link } from "gatsby";

const CommitToWellness = () => {
  return (
    <div className="flex flex-col gap-[1.875rem] bg-[#CADED5] text-[#274240] px-[28.875rem] pt-[9.313rem] pb-[7.438rem] text-center items-center">
      <h2 className="text-[2.375rem] font-[500] text-[2.375rem] leading-[3.125rem]">
        Commit to Your Wellness
      </h2>
      <p className="text-[1.5rem] max-w-[44.438rem] leading-[1.95rem] m-0">
        Neglecting your physical and mental health can lead to burnout, stress,
        and a host of physical ailments.
      </p>
      <p className="text-[1.125rem] max-w-[62.375rem] leading-[1.375rem] m-0">
        That's why we want to make it easy for you to put yourself first. By
        investing in your well-being, you'll not only enhance your quality of
        life now; but equip yourself to face the future with greater strength
        and resilience.
      </p>
      <button className="bg-[#274240] text-[#CADED5] pt-[0.5rem] px-[1.375rem] pb-[0.625rem] rounded-[6.25rem] font-[400] ">
        <Link to="/wellness-bookings">View Pricing</Link>
      </button>
    </div>
  );
};

export default CommitToWellness;
