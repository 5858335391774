import React from "react";

import About from "../components/HomePage/About";
import IntoStudio from "../components/HomePage/IntoStudio";
import ReformerYoga from "../components/HomePage/ReformerYoga";
import TheStudio from "../components/HomePage/TheStudio";
import VideoContainer from "../components/HomePage/VideoContainer";
import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";
import Wellness from "../components/HomePage/Wellness";

function HomePage() {
  const { data } = useFetch({
    params: "home-page",
    populate:
      "introVideo,bannerMobile,bannerURL,step_1.background,step2.background,step3.background,step4.background",
  });
  return (
    <Layout>
      {data && (
        <>
          <VideoContainer data={data} page="homepage" />
          <About data={data} />
          {/* <div className="max-w-[100vw] overflow-hidden">
            <IntoStudio data={data} />
          </div> */}
          <Wellness />
          <ReformerYoga data={data} />
          <TheStudio data={data} />
        </>
      )}
    </Layout>
  );
}

export default HomePage;
