import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import logo from "../assets/svg/GU_logo.svg";
import full_wellness_logo from "../assets/images/wellness/GU_Full_Wellness_Logo.svg";
import gu_wellness_mobile from "../assets/svg/GU_Wellness_Mobile.svg";
import hamburger from "../assets/images/wellness/hamburger.svg";
import hamburger_mobile from "../assets/images/wellness/hamburger_mobile.svg";
import close from "../assets/images/wellness/close.svg";
import close_mobile from "../assets/images/wellness/close_mobile.svg";
import close_icon from "../assets/images/wellness/close_icon.png";
import WellnessPopup from "./Wellness/PopUp/WellnessPopup";
import GetInTouchPopup from "./Wellness/PopUp/GetInTouchPopup";
import Sidebar from "./mobile/Sidebar";

// This import will be removed with the next GU website update
import original_logo from "../assets/svg/logo.svg";
import { navWellnessLinks1, navLinks } from "../utils/data";

function Navbar(props: any) {
  const { page } = props;
  console.log(page);
  // const [navLinks] = useState({
  //   list: ["About", "Studios", "Classes", "Timetable"],
  //   active: "",
  // });
  const [navWellnessLinks] = useState({
    list: ["Book Now", "Offerings", "Spaces", "Contact", "Movement"],
    active: "",
  });
  const router = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const toggleSidebar = () => {
    setIsAnimating(true);
    setSidebarOpen(!sidebarOpen);
  };

  // useEffect(() => {
  //   // Function to set the height of the navbar to match the height of the banner image
  //   const updateNavbarHeight = () => {
  //     const navbar = document.getElementById("mobile-navbar");
  //     const bannerImage = document.getElementById("banner-image");
  //     const textScroller = document.getElementById("text-scroller");
  //     if (navbar && bannerImage) {
  //       setTimeout(() => {
  //         const bannerHeight = bannerImage.offsetHeight;
  //         const textScrollerHeight = textScroller
  //           ? textScroller.offsetHeight
  //           : 0;
  //         navbar.style.height = `${bannerHeight}px`;
  //       }, 100);
  //     }
  //   };

  //   // Set height on component mount and whenever the window is resized
  //   updateNavbarHeight();
  //   window.addEventListener("resize", updateNavbarHeight);

  //   // Cleanup event listener on unmount
  //   return () => {
  //     window.removeEventListener("resize", updateNavbarHeight);
  //   };
  // }, [sidebarOpen]);

  // Determine if the current page is wellness or a wellness-related page
  // const containsWellness = router.pathname.includes("/wellness");
  // const isWellness = router.pathname.replace(/\/$/, "") === "/wellness";

  const [showModal, setShowModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  return (
    <>
      {page !== "wellness" ? (
        <>
          <div className="bg-[#E0C3B2] w-full  top-0 z-10">
            <div className="mx-auto max-w-[1344px] flex justify-between px-[2.8125rem] pt-[1.75rem] pb-[1.75rem] ">
              <div>
                <Link to="/">
                  <img
                    src={original_logo}
                    className="w-[140px] sm:w-[230px]"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="top-bar-right">
                <div className="xxx:flex hidden">
                  <ul className="menu ">
                    {/* {navLinks.map((item: string) => (
                      <li
                        className={`${
                          router.pathname == "/" + item.href
                            ? "is-active-link"
                            : ""
                        } uppercase  `}
                      >
                        <a
                          className="amber-text"
                          href={item.href}
                        >
                          {item.text}
                        </a>
                      </li>
                    ))} */}

                    {navLinks.map((item, index) => {
                      return (
                        <li key={item.id}>
                          <Link
                            to={item.href}
                            className="uppercase amber-text"
                            activeClassName="is-active-link"
                          >
                            {item.text}
                          </Link>
                        </li>
                      );
                    })}

                    {/* <li>
                <a className="uppercase" href="/movement" >
                  CHALLENGE
                </a>
              </li> */}
                    <div className="flex lg:ml-10">
                      <Link
                        style={{ marginRight: "8px" }}
                        className="px-4 py-2 bg-[#274C3D] text-white rounded-full font-[400] uppercase text-4"
                        to="/wellness"
                      >
                        Wellness
                      </Link>

                      <Link
                        className="px-4 py-2 bg-[#5C3D2E] text-white rounded-full font-[400] capitalize text-4"
                        to="/join-now"
                      >
                        Join Now
                      </Link>
                    </div>
                    {/* <li>
                <a
                  className="button hollow uppercase tracking-wider font-bold amber-text"
                  href="/timetable"
                >
                  Log In
                </a>
              </li> */}
                  </ul>
                </div>
                <div className="xxx:hidden block">
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* This will be the navbar used once the GU changes are implemented */}
          <div className="hidden md:flex bg-transparent w-full max-w-[1440px] mx-auto right-0 left-0 top-0 z-10 absolute justify-center">
            <div className="w-[90%] flex mt-[3.5rem]">
              <div className="flex-1 flex"></div>
              <div className="flex-1 flex relative items-center justify-center my-auto mx-auto w-[140px] h-[110px] sm:w-[230px] overflow-hidden">
                <div className="hidden md:flex  flex-col items-center justify-center gap-[0.875rem]">
                  {page === "wellness" ? (
                    <>
                      <Link className="w-min h-min" to="/">
                        {/* <div> */}
                        <img
                          src={logo}
                          className="w-auto h-full object-contain mx-auto max-w-[3.91rem] max-h-[3.278rem]"
                          alt="logo"
                        />

                        {/* <p>Text and stuff</p> */}
                        {/* </div> */}
                      </Link>
                      <p
                        className="uppercase text-white text-[1rem] font-[500] leading-[1.3rem]"
                        onClick={() => setShowModal(true)}
                      >
                        stronger body. stronger mind
                      </p>
                    </>
                  ) : (
                    <>
                      <Link to="/">
                        {/* <div> */}
                        <img src={full_wellness_logo} alt="logo" />

                        {/* <p>Text and stuff</p> */}
                        {/* </div> */}
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <div className="flex-1 flex justify-end items-start mt-4">
                <div className="md:flex hidden  space-x-4 mr-[2.44rem]">
                  <Link
                    to="/"
                    className="px-4 py-2 bg-[#5C3D2E] text-white rounded-full font-[400] capitalize text-4"
                  >
                    Movement
                  </Link>
                  <Link
                    to="/wellness#download"
                    className="px-4 py-2 bg-[#274C3D] text-white rounded-full font-[400] uppercase text-4"
                  >
                    Join now
                  </Link>
                </div>
                <div className="flex items-center justify-end mt-2">
                  <button onClick={toggleSidebar} className="text-2xl">
                    <img
                      src={page === "wellness" && hamburger_mobile}
                      alt="Menu Icon"
                      className={`w-6 h-6 min-w-[1.5rem] min-h-[1.5rem] block`}
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div
              className={`${
                page === "wellness" ? "bg-[#CADED5]" : "bg-[#E1C4B2]"
              } fixed top-0 right-0 h-full w-[40%] max-w-[455px] transform transition-transform duration-500 ease-in-out ${
                sidebarOpen ? "translate-x-0" : "translate-x-full"
              }`}
              onAnimationEnd={() => setIsAnimating(false)}
              style={{
                pointerEvents: isAnimating || sidebarOpen ? "auto" : "none",
              }}
            >
              <div className="absolute top-4 right-4">
                <button onClick={toggleSidebar} className="text-2xl">
                  <img src={close} alt="Close Icon" className="w-6 h-6" />
                </button>
              </div>
              <ul className="list-none flex flex-col gap-[1.25rem] items-center mt-[4.44rem] px-[4.00rem]">
                {page !== "wellness" &&
                  navLinks.list.map((item, index) => (
                    <li
                      key={index}
                      className={`${
                        router.pathname === "/" + item.toLowerCase()
                          ? "is-active-link"
                          : ""
                      } uppercase`}
                    >
                      <Link className="amber-text" to={item.toLowerCase()}>
                        {item}
                      </Link>
                    </li>
                  ))}
                {page === "wellness" &&
                  navWellnessLinks1.map((item, index) => {
                    const customClass =
                      item.text === "movement"
                        ? "px-4 py-2 bg-[#5C3D2E] text-white rounded-full text-xl capitalize text-4 mt-6"
                        : "px-4 py-2  text-[#274240] hover:text-[#5C3D2E] rounded-full text-xl capitalize text-4";
                    return (
                      <li key={item.id} onClick={toggleSidebar}>
                        <Link to={item.href} className={customClass}>
                          {item.text}
                        </Link>
                      </li>
                    );

                    // <li
                    //   key={index}
                    //   className={`${
                    //     router.pathname === "/" + item.toLowerCase()
                    //       ? "is-active-link"
                    //       : ""
                    //   } uppercase`}
                    // >
                    //   {item === "Contact" ? (
                    //     <span
                    //       className="text-[#274240] cursor-pointer"
                    //       onClick={() => setShowContactModal(true)}
                    //     >
                    //       {item}
                    //     </span>
                    //   ) : item === "Movement" ? (
                    //     <button className="rounded-full bg-[#693927] text-[#FFFFFF] py-[10px] px-[22px] cursor-pointer hover:border hover:border-solid hover:border-[#693927] hover:text-[#693927] hover:bg-transparent transition duration-500 ease-in-out">
                    //       <Link to="/movement">{item} </Link>
                    //     </button>
                    //   ) : (
                    //     <Link
                    //       className="text-[#274240]"
                    //       to={"wellness-" + item.toLowerCase()}
                    //     >
                    //       {item}
                    //     </Link>
                    //   )}
                    // </li>
                  })}

                {/* <li>
            <a
              className="uppercase amber-text"
              href="https://www.hiitrepublic.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              HIIT Republic
            </a>
          </li>
          <li>
            <a
              className="uppercase amber-text"
              href="https://www.clublime.com.au/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Club Lime
            </a>
          </li> */}
                {/* <li className="pt-[2.75rem]">
                  <Link
                    className={`${
                      containsWellness
                        ? "text-white bg-[#693927]"
                        : "text-[#CADED5] bg-[#274240]"
                    } uppercase text-[1rem] leading-[1.2rem] pt-[8px] pr-[22px] pb-[10px] pl-[22px] rounded-[100px] tracking-widest font-[400] `}
                    to={containsWellness ? "/movement" : "/wellness"}
                  >
                    {containsWellness ? "Movement" : "Wellness"}
                  </Link> */}

                {/* <Link
                className="uppercase pt-[8px] pr-[22px] pb-[10px] pl-[22px] tracking-widest rounded-[100px] "
                to="/wellness"
              >
                Wellness
              </Link>
            )} */}
                {/* </li> */}
              </ul>
            </div>
            {showModal && <WellnessPopup onClose={() => setShowModal(false)} />}
            {showContactModal && (
              <GetInTouchPopup onClose={() => setShowContactModal(false)} />
            )}
          </div>
          {/* Mobile Navbar */}
          <div className="md:hidden flex w-full px-4 mt-[3.5rem] bg-transparent text-white max-w-[1440px] mx-auto right-0 left-0 top-0 z-10 absolute justify-center ">
            <div className="flex flex-col gap-[1.2rem] w-full">
              <button className="self-end" onClick={toggleSidebar}>
                <img src={hamburger_mobile} alt="Menu Icon" />
              </button>
              <div className="block md:hidden self-center w-fit">
                <img
                  src={gu_wellness_mobile}
                  className="w-auto h-full object-contain mx-auto"
                  alt="logo"
                />
              </div>
            </div>

            {/* Mobile Navbar Content */}
            <div
              id="mobile-navbar"
              className={`${
                page === "wellness" ? "bg-[#CADED5]" : "bg-[#E1C4B2]"
              } fixed top-0 left-0 w-full transform transition-transform duration-500 ease-in-out ${
                sidebarOpen ? "translate-y-0" : "-translate-y-full"
              }`}
              onAnimationEnd={() => setIsAnimating(false)}
              style={{
                overflow: "hidden",
                pointerEvents: sidebarOpen ? "auto" : "none",
              }}
            >
              <div className="absolute top-[44px] right-[33px]">
                <button onClick={toggleSidebar}>
                  <img src={close_icon} alt="Close Icon" className="w-6 h-6 " />
                </button>
              </div>
              {/* Sidebar Content */}
              <ul className="uppercase text-[16px] font-[400] list-none flex flex-col gap-[1rem] items-center mt-[4.44rem] px-[4.00rem] h-screen">
                {navWellnessLinks1.map((item, index) => {
                  const customClass =
                    item.text === "movement"
                      ? "px-4 py-2 bg-[#5C3D2E] text-white rounded-full text-xl capitalize text-4 mt-6"
                      : "px-4 py-2  text-[#274240] hover:text-[#5C3D2E] rounded-full text-xl capitalize text-4";
                  return (
                    <li key={item.id} onClick={toggleSidebar}>
                      <Link to={item.href} className={customClass}>
                        {item.text}
                      </Link>
                    </li>
                  );
                  // <li
                  //   key={index}
                  //   className={`${
                  //     router.pathname === "/" + item.toLowerCase()
                  //       ? "is-active-link"
                  //       : ""
                  //   } uppercase`}
                  // >
                  //   {item === "Contact" ? (
                  //     <span
                  //       className="text-[#CADED5] cursor-pointer"
                  //       onClick={() => setShowContactModal(true)}
                  //     >
                  //       {item}
                  //     </span>
                  //   ) : item === "Book Now" ? (
                  //     <button className="rounded-full bg-[#CADED5] text-[#274240] py-[10px] px-[22px] cursor-pointer hover:border hover:border-solid hover:border-[#CADED5] hover:text-[#CADED5] hover:bg-[#274240] transition duration-500 ease-in-out">
                  //       {item}
                  //     </button>
                  //   ) : item === "Movement" ? (
                  //     <button className="rounded-full bg-[#693927] text-[#FFFFFF] py-[10px] px-[22px] cursor-pointer hover:border hover:border-solid hover:border-[#693927] hover:text-[#693927] hover:bg-transparent transition duration-500 ease-in-out">
                  //       <Link to="/movement">{item} </Link>
                  //     </button>
                  //   ) : (
                  //     <Link
                  //       className="text-[#CADED5]"
                  //       to={"wellness-" + item.toLowerCase()}
                  //     >
                  //       {item}
                  //     </Link>
                  //   )}
                  // </li>
                })}
              </ul>
            </div>
          </div>{" "}
        </>
      )}
    </>
  );
}

export default Navbar;
