import React from "react";
import ClassIntro from "../components/AboutPage/ClassIntro";
import ReformerNMat from "../components/AboutPage/ReformerNMat";
import StudioSlider from "../components/AboutPage/StudioSlider";
import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";

function About() {
  const { data } = useFetch({
    params: "about-page",
    populate:
      "topBanner.background,midBanner.background,midCarousels.images,belowAccessCarousel",
  });
  return (
    <Layout>
      <div className="max-w-[100vw] overflow-hidden">
        <ReformerNMat data={data} />
        <ClassIntro data={data} />
        <StudioSlider data={data} />
      </div>
    </Layout>
  );
}

export default About;
