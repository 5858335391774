import React from "react";

import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";
import JoinTheJourney from "../components/Wellness/JoinTheJourney/JoinTheJourney";
import TextScroller from "../components/Wellness/TextScroller/TextScroller";
import OurOfferings from "../components/Wellness/OurOfferings/OurOfferings";
import OurSpaces from "../components/Wellness/OurSpaces/OurSpaces";
import FollowTheWellness from "../components/FollowTheWellness/FollowTheWellness";
import Header from "../components/WellnessJoinNow/Header";
import DownloadSection from "../components/WellnessJoinNow/DownloadSection";
import JoinNowBackgroundImage from "../assets/images/wellness/JoinNowBackgroundImage.png";

function Wellness() {
  // const { data } = useFetch({
  //   params: "home-page",
  //   populate:
  //     "introVideo,bannerMobile,bannerURL,step_1.background,step2.background,step3.background,step4.background",
  // });
  return (
    <Layout page="wellness">
      {/* {data && ( */}
      <div className="wellness-home">
        {/* <VideoContainer data={data} /> */}
        <Header
          bottomText="wellness"
          bottomImage={true}
          // middleText="book trial session today"
          bottomTextColor="#CADED5"
        />
        <TextScroller />
        <JoinTheJourney />
        <OurOfferings />

        <section
          id="download"
          style={{
            backgroundImage: `url(${JoinNowBackgroundImage})`,
          }}
          className="bg-gray-900 bg-cover bg-center relative"
        >
          {/* Wrapper for background and overlay */}
          <div className="relative">
            {/* Green overlay */}
            <div className="absolute inset-0 bg-[#406651] opacity-50 z-0"></div>
            {/* Background image */}
            <div className="relative z-10">
              <DownloadSection />
            </div>
          </div>
        </section>
        <OurSpaces />
        {/* <AppSection /> */}
        <FollowTheWellness />
        <TextScroller />
      </div>
      {/* )} */}
    </Layout>
  );
}

export default Wellness;
