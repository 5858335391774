import React from "react";
import Title from "../../WellnessJoinNow/Title";
import { Link } from "gatsby";

const JoinTheJourney = () => {
  return (
    <section className="bg-black text-white py-20 ">
      <div className="container">
        <div className="text-center">
          <Title title="Join the journey" />
        </div>
        <h6 className="text-center mb-5">Breathe in. Chill out. Reset</h6>
        <p className="max-w-3xl mx-auto text-center mb-10">
          In a world that constantly demands more, our wellness space offers a
          sanctuary to recharge and recover. From the healing power of ice
          baths, the detoxing benefits of saunas, and dedicated recovery spaces
          designed to soothe both body and mind, we have everything you need to
          return to life revitalised and ready to thrive.
        </p>
        <div className="text-center">
          <Link
            to="/wellness#download"
            className="px-4 py-2 bg-[#CADED5] hover:bg-[#274240] text-[#274240] hover:text-[#CADED5] rounded-full font-[400] uppercase text-4"
          >
            Join now
          </Link>
        </div>
      </div>
    </section>
  );
};

export default JoinTheJourney;
