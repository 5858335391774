import React, { useEffect, useState } from "react";
import iceBathImage from "../../../assets/svg/wellness_popup.svg";
import green_logo from "../../../assets/svg/GU_logo_green.svg";

const WellnessPopup = ({ onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Trigger fade-in effect on mount
    setVisible(true);
  }, []);

  const handleClose = () => {
    // Trigger fade-out effect before closing
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, 300); // This timeout matches the transition duration
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
        visible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="bg-[#ffffff] max-w-[1041px] max-h-[700px] w-full py-[2.688rem] md:py-[5.625rem] px-[1.813rem] md:px-[8.125rem] relative">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-[#274240] text-2xl"
        >
          &times;
        </button>

        {/* Form Header */}
        <h2 className="text-[#274240] text-[2.188rem] md:text-[2.375rem] font-[500] mb-[1.125rem] md:mb-[2rem] text-left md:text-center">
          GET IN TOUCH
        </h2>
        <p className="text-[#274240] mb-[2rem] text-1 md:text-[1.5rem] font-[400] text-left md:text-center">
          Got a question or want to find out more? Just fill in your details
          below and one of our team will be in touch with you as soon as
          possible.
        </p>

        {/* Contact Form */}
        <form className="" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[0.56rem] mb-[0.438rem]">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="First Name*"
              className="getInTouchFormSmall"
              required
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Last Name*"
              className="getInTouchFormSmall"
              required
            />
          </div>
          <div className="mb-[0.438rem]">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address*"
              className="getInTouchFormSmall"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-[0.56rem] mb-[0.438rem]">
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="Phone number*"
              className="getInTouchFormSmall"
              required
            />
            <select
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              className="getInTouchFormSmall"
            >
              <option value="">Location</option>
              <option value="Location 1">Location 1</option>
              <option value="Location 2">Location 2</option>
              <option value="Location 3">Location 3</option>
            </select>
          </div>
          <div className="md:hidden flex flex-col gap-[1rem]">
            <p className="m-0 text-[#274240] text-1 font-[400]">
              How can we help?
            </p>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              style={{ borderRadius: "20px !important", height: "100%" }}
              className="getInTouchFormSmallMobile"
            ></textarea>
          </div>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="How can we help?"
            className="hidden md:block getInTouchFormSmall"
          ></textarea>
          <div className="flex  md:justify-center mt-[1.5rem] md:mt-[2.625rem]">
            <button
              type="submit"
              style={{ borderRadius: "100px" }}
              className="px-[37px] md:px-[22px] py-[10px] text-[1rem] bg-[#274240] text-[#CADED5]  font-[400] capitalize"
            >
              Enquire Now
            </button>
            <button
              onClick={handleClose}
              style={{ borderRadius: "100px" }}
              className="md:hidden px-[37px] md:px-[22px] py-[10px] text-[1rem] bg-transparent text-[#274240]  font-[400] capitalize border border-[#274240] border-solid"
            >
              cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WellnessPopup;
