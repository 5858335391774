import React, { useState } from "react";
import { useLocation } from "@reach/router";
import GetInTouchForm from "./GetInTouchForm";
import GetInTouchFormSmall from "./GetInTouchFormSmall";

const GetInTouch = () => {
  const router = useLocation();
  const isWellness = router.pathname === "/wellness/";
  const isWellnessFacilities = router.pathname === "/wellness-facilities/";
  const isWellnessPricing = router.pathname === "/wellness-bookings/";
  const isWellnessContact = router.pathname === "/wellness-contact/";

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    message: "",
  });

  return (
    <>
      {isWellnessPricing ? (
        <GetInTouchForm />
      ) : isWellness ? (
        <GetInTouchFormSmall />
      ) : (
        <p>dfdf</p>
      )}
    </>
  );
};

export default GetInTouch;
