import React from "react";
import IphoneImage from "../../assets/images/wellness/IphoneImage.svg";
import AppStoreButton from "../../assets/svg/AppStoreButton.svg";
import GooglePlayButton from "../../assets/svg/GooglePlayButton.svg";

function DownloadSection() {
  return (
    <>
      {/* Phones Section */}
      <div className=" max-w-5xl md:max-w-xl lg:max-w-6xl  md:w-[600px] lg:w-[75%]  mx-auto md:mx-0 z-100">
        <img
          src={IphoneImage}
          alt="iPhone image"
          className="w-full object-cover"
        />
      </div>

      {/* Text Section */}
      <div className="md:absolute md:top-[20%] lg:top-[30%] md:left-[55%] -lg:right-[11%] max-w-sm md:max-w-sm lg:max-w-xl pr-2 mx-auto pb-20">
        <h3 className="text-xl lg:text-3xl text-[#CADED5] font-medium  lg:mb-4 text-center md:text-left">
          TO JOIN OR BOOK
        </h3>
        <h3 className="text-xl lg:text-3xl text-[#CADED5] font-medium mb-7 md:mb-8 text-center md:text-left">
          DOWNLOAD THE APP
        </h3>

        <p className="text-lg  md:max-w-lg text-white text-center md:text-left">
          Make a booking or buy one of our Wellness Membership packages by
          simply downloading our GROUNDUP App via the app store on both iOS and
          Android.
        </p>
        <p className="text-lg  md:max-w-lg text-white text-center md:text-left">
          To celebrate our opening, we are offering a complimentary wellness
          session. Download the app and use code{" "}
          <span className="font-bold">GUWELLNESSFREE</span> to redeem.
        </p>

        {/* App Store Buttons */}
        <div className="flex justify-center md:justify-start mt-14 md:mb-8 space-x-4 ">
          <a
            href="https://apps.apple.com/au/app/groundup/id6479574546?utm_source=email&utm_medium=email+marketing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={AppStoreButton} alt="App Store" className="w-40" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.hapana.groundupapp&utm_source=email&utm_medium=email+marketing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={GooglePlayButton} alt="Google Play" className="w-40" />
          </a>
        </div>
      </div>
    </>
  );
}

export default DownloadSection;
