import React from "react";
import AppStoreIcon from "../../../assets/images/wellness/apple.svg";
import AppStoreIconMobile from "../../../assets/images/wellness/AppStoreIconDarkGreen.svg";
import GooglePlayIcon from "../../../assets/images/wellness/apple.svg";
import AppImage from "../../../assets/images/wellness/appImage.svg";
import GUMobileAppIcon from "../../../assets/svg/GU_App_Icon.svg";
import { useLocation } from "@reach/router";

const AppSection: React.FC = () => {
  const router = useLocation();
  const isWellness = router.pathname.replace(/\/$/, "") === "/wellness";
  const isWellnessFacilities = router.pathname === "/wellness-facilities";
  return (
    <>
      <div
        className={`${
          isWellness ? "bg-[#CADED5]" : "bg-[#000000]"
        } hidden md:flex flex-col md:flex-row items-center `}
      >
        <div className=" w-full md:w-1/2">
          <img
            src={AppImage}
            alt="App preview"
            className="w-full h-auto object-cover"
          />
        </div>
        <div className="flex flex-col gap-[2.38rem] w-full md:w-1/2 text-left py-[13.38rem] pl-[6.13rem] pr-[13.44rem]  md:mt-0">
          <h2
            className={`${
              isWellness ? "text-[#203731]" : "text-[#CADED5]"
            } text-2xl font-semibold `}
          >
            DOWNLOAD THE APP HERE
          </h2>
          <p className={`${isWellness ? "text-[#203731]" : "text-[#ffffff]"} `}>
            Enhance your Wellness experience with our app that lets you view,
            book and add your name to the waitlist for sessions, gain facility
            access with a tap of your phone, update your personal details and
            receive the latest news and offers.
          </p>
          <div className="flex space-x-4">
            <button
              className={`${
                isWellness ? "bg-[#203731]" : "bg-[#274240] "
              } flex items-center px-4 py-2  text-[#CADED5] rounded-full`}
            >
              <img
                src={AppStoreIcon}
                alt="App Store"
                className="w-6 h-6 mr-2"
              />
              App Store
            </button>
            <button
              className={`${
                isWellness ? "bg-[#203731]" : "bg-[#274240]"
              } flex items-center px-4 py-2  text-[#CADED5] rounded-full`}
            >
              <img
                src={GooglePlayIcon}
                alt="Google Play"
                className="w-6 h-6 mr-2"
              />
              Google Play
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Section */}
      <div
        className={`${
          isWellness ? "bg-[#274240]" : "bg-[#274240]"
        } md:hidden flex flex-col md:flex-row items-center `}
      >
        <div className="flex flex-col gap-[2.38rem] w-full text-left py-[65px] px-[26px] ">
          <div className="flex justify-between">
            <h2
              className={`${
                isWellness ? "text-[#CADED5]" : "text-[#CADED5]"
              } text-[35px] font-[500] leading-[44px] flex-1`}
            >
              DOWNLOAD THE APP
            </h2>
            <div className="flex flex-1 items-centered">
              <img
                src={GUMobileAppIcon}
                alt="Google Play"
                className="w-[66px] h-[66px] ml-auto"
              />
            </div>
          </div>

          <p
            className={`${
              isWellness ? "text-[#CADED5]" : "text-[#CADED5]"
            } mb-0`}
          >
            Enhance your Wellness experience with our app that lets you view,
            book and add your name to the waitlist for sessions, gain facility
            access with a tap of your phone, update your personal details and
            receive the latest news and offers.
          </p>

          <div className="flex space-x-4">
            <button
              className={`${
                isWellness ? "bg-[#CADED5]" : "bg-[#CADED5] "
              } flex items-center px-[1.5rem] py-[1.063rem]  text-[#274240] rounded-full`}
            >
              <img
                src={AppStoreIconMobile}
                alt="App Store"
                className="w-6 h-6 mr-2"
              />
              App Store
            </button>
            <button
              className={`${
                isWellness ? "bg-[#CADED5]" : "bg-[#CADED5]"
              } flex items-center px-[1.5rem] py-[1.063rem]  text-[#274240] rounded-full`}
            >
              <img
                src={AppStoreIconMobile}
                alt="Google Play"
                className="w-6 h-6 mr-2"
              />
              Google Play
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppSection;
