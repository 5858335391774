import React from "react";

import { Link } from "gatsby";
import { offerings } from "../../utils/data";

function Wellness() {
  return (
    <div className="bg-[#274240]">
      <div className="container grid gap-8 xl:grid-cols-2 md:gap-4 py-[150px]">
        <div className="grid grid-cols-1 sm:grid-cols-2  gap-[20px] md:gap-[25px]">
          {offerings.map((offering, index) => (
            <div>
              <div
                key={index}
                className="relative group w-full h-auto overflow-hidden "
              >
                {/* Image */}
                <img
                  src={offering.image}
                  alt={offering.title}
                  className="w-full h-auto object-cover"
                />

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-black/50 opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300">
                  {/* Upper Text */}
                  <span className="absolute top-0 right-0 text-white text-[28px] sm:text-[30px] md:text-[32px] font-medium uppercase opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300 pointer-events-none -mt-[10px]">
                    {offering.upperText}
                  </span>

                  {/* Middle Text */}
                  <span className="absolute inset-0 flex items-center justify-center text-white text-xs text-center px-4 opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                    {offering.middleText}
                  </span>

                  {/* Lower Text */}
                  <span className="absolute bottom-0 left-0 text-white text-[28px] sm:text-[30px] -mb-[10px] opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300">
                    {offering.lowerText}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="text-right mt-6">
            <h3
              className={`text-[#CADEDF] uppercase text-4xl sm:text-7xl font-medium  mb-6`}
            >
              wellness
            </h3>
          </div>
          <p className="text-right text-[#CADEDF] text-xl md:text-lg lg:text-2xl mt-20 max-w-[400px] ml-auto">
            Strengthen your body. Refocus your mind. Enhance your wellbeing.
          </p>
          <p className="text-right text-[#CADEDF] text-md md:text-sm lg:text-xl mt-10">
            This is your ultimate space for rejuvenation and holistic wellness.
            Discover the perfect balance of mind and body with our
            state-of-the-art facilities, including Traditional and Infrared
            saunas, invigorating Ice Baths, and a soothing Red Light Therapy
            bed. Immerse yourself in our serene environment designed to
            revitalize your spirit and enhance your overall well-being.
          </p>
          <div className="flex justify-end md:justify-end space-x-4 md:mb-[46px] mt-14 text-right">
            <div>
              <Link
                to="/wellness#download"
                className="px-4 py-2 bg-[#CADED5] hover:bg-[#274240] text-[#274240] hover:text-[#CADED5] hover:outline hover:outline-2 hover:outline-[#CADED5] rounded-full font-[400] uppercase text-4"
              >
                Join now
              </Link>
            </div>
            <div className="btn-container text-center ">
              <Link
                to="/sale-lead-form-dickson"
                className="px-4 py-2 bg-transparent hover:bg-[#CADED5] text-[#CADED5] hover:text-[#274240] border border-[#CADED5] rounded-full font-[400] uppercase text-4"
              >
                Enquire
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wellness;
