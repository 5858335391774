import React, { useState } from "react";

const GetInTouchFormSmall = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <div className="p-6 bg-[#D8E2DB] rounded-lg w-full max-w-[500px] mx-auto">
      <h3 className="text-[#274240] text-[1.5rem] font-[400] mb-[0.5rem]">
        DICKSON
      </h3>
      <div className="flex flex-col md:flex-row md:gap-[1.2rem]">
        <h2 className="text-[#274240] text-[2.375rem] font-[500] mb-[2rem]">
          GET IN TOUCH
        </h2>
        <p className="text-[#274240] mb-6">
          Got a question or want to find out more? Just fill in your details and
          we'll be in touch with you soon.
        </p>
      </div>

      <form className="space-y-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="First Name*"
            className="w-full p-3 border rounded-lg"
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Last Name*"
            className="w-full p-3 border rounded-lg"
            required
          />
        </div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Email address*"
          className="w-full p-3 border rounded-lg"
          required
        />
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          placeholder="Phone number*"
          className="w-full p-3 border rounded-lg"
          required
        />
        <textarea
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          placeholder="How can we help?"
          className="w-full p-3 border rounded-lg h-32"
        ></textarea>
        <div className="flex space-x-4">
          <button
            type="submit"
            className="px-6 py-3 bg-[#274C3D] text-white rounded-full font-bold uppercase"
          >
            Enquire Now
          </button>
          <button
            type="button"
            className="px-6 py-3 bg-[#D8E2DB] text-[#274240] rounded-full font-bold uppercase border"
            onClick={() => {
              // Assuming there is a handler to close the modal/form
              console.log("Cancelled");
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default GetInTouchFormSmall;
