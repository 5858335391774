import React, { useEffect, useState } from "react";
import { Router, useLocation } from "@reach/router";

import PrivateRoute from "../components/PrivateRoute";
import AboutPage from "../routes/About";
import ClassesPage from "../routes/Classes";
import HomePage from "../routes/HomePage";
import IpadCheckin from "../routes/IpadCheckin";
import Movement from "../routes/Movement";
import StudioDetail from "../routes/StudioDetail";
import Studios from "../routes/Studios";
import TimeTable from "../routes/Time-table";
import IpadCheckinHiit from "../routes/IpadCheckinHiit";
import FreeClass from "../routes/FreeClass";
import SuspensionForms from "../routes/SuspensionForms";
import CancellationForms from "../routes/CancellationForms";
import EnquiryForms from "../routes/EnquiryForms";
import Wellness from "../routes/Wellness";
import WellnessFacilities from "../routes/Wellness-Facilities";
import WellnessBookings from "../routes/Wellness-Bookings";
import WellnessContact from "../routes/Wellness-Contact";
import WellnessJoinNow from "../routes/Wellness-Join-Now";
import JoinNow from "../routes/JoinNow";
import Investors from "../routes/Investors";
import NotFoundPage from "./404";

const IndexPage = () => {
  const location = useLocation();
  const [modal, setModal] = useState<boolean>(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.sessionStorage.getItem("modal") !== "false" &&
        window.sessionStorage.setItem("modal", "true");
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.getItem("modal") !== "false" &&
      window.sessionStorage.setItem("modal", "true");
  }, []);

  useEffect(() => {
    window.sessionStorage.getItem("modal") == "true"
      ? setModal(true)
      : setModal(false);
  }, [modal, location.pathname]);

  useEffect(() => {
    if (location.pathname == "/") {
      window.sessionStorage.setItem("modal", "true");
      setModal(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.sessionStorage.getItem("modal") !== "false" &&
        window.sessionStorage.setItem("modal", "true");
    }
  }, []);

  return (
    <>
      <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5FHZF4R4"
            height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>`}</noscript>
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5FHZF4R4');
        `}</script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-M4HF3B83D0"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-M4HF3B83D0');
            `}
      </script>
      <Router basepath="/">
        <PrivateRoute path="/" component={HomePage} />
        <PrivateRoute path="/about" component={AboutPage} />
        <PrivateRoute path="/classes" component={ClassesPage} />
        <PrivateRoute path="/studios" component={Studios} />
        <PrivateRoute path="/free-class" component={FreeClass} />
        <PrivateRoute path="/timetable" component={TimeTable} />
        <PrivateRoute path="/ipad-checkin" component={IpadCheckin} />
        <PrivateRoute path="/movement" component={Movement} />
        <PrivateRoute path="/ipad-checkin-hiit" component={IpadCheckinHiit} />
        <PrivateRoute path="/join-now" component={JoinNow} />
        <PrivateRoute path="/suspend-membership" component={SuspensionForms} />
        <PrivateRoute path="/cancel-membership" component={CancellationForms} />
        <PrivateRoute path="/enquiry" component={EnquiryForms} />
        <PrivateRoute path="/wellness" component={Wellness} />
        <PrivateRoute path="/investors" component={Investors} />
        <PrivateRoute
          path="/wellness-facilities"
          component={WellnessFacilities}
        />
        <PrivateRoute path="/wellness-bookings" component={WellnessBookings} />
        <PrivateRoute path="/wellness-contact" component={WellnessContact} />
        <PrivateRoute path="/wellness-join-now" component={WellnessJoinNow} />
        <PrivateRoute path="/:id" component={StudioDetail} />
        <PrivateRoute path="*" component={NotFoundPage} />
      </Router>
      <div id="hapana-script"></div>
    </>
  );
};

export default IndexPage;
