import React from "react";
import PilatesMenu from "../components/ClassesPage/PilatesMenu";
import PilatesReformer from "../components/ClassesPage/PilatesReformer";
import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";

function Classes() {
  const { data } = useFetch({
    params: "classes-page",
    populate: "topBanner.background,classes.media,classes.bestThing",
  });
  return (
    <Layout>
      <PilatesReformer data={data} />
      <PilatesMenu data={data} />
    </Layout>
  );
}

export default Classes;
