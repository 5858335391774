import React from "react";
import saunaImage from "../../../assets/svg/facilities_sauna.svg";
import iceBathImage from "../../../assets/svg/facilities_ice_bath.svg";
import recoveryImage from "../../../assets/svg/facilities_recovery.svg";
import saunaVideo from "../../../assets/svg/facilities_ice_bath.svg";

const FacilitiesGallery = () => {
  const facilities = [
    {
      title: "SAUNAS",
      description:
        "By elevating your core temperature to induce sweating, you can experience a range of health benefits. These include flushing out toxins, improving blood flow to support cardiovascular health, boosting white blood cells to help your immune system, alleviating pain and relieving muscle and mind tension.",
      image: saunaImage,
      video: saunaVideo,
    },
    {
      title: "ICE BATHS",
      description:
        "Experience the powerful anti-inflammatory benefits of cold-water immersion. The cold temperature constricts blood vessels and decreases metabolic activity, which limits swelling and tissue breakdown. As your body warms up post-bath, blood flow increases, flushing out metabolic waste and speeding up the healing process, to help reduce muscle soreness, boost circulation, improve your immune system and even aid with sleep.",
      image: iceBathImage,
    },
    {
      title: "RECOVERY SPACES",
      description:
        "Our dedicated recovery spaces allow you to focus on healing both body and mind. From equipment such as foam rollers and massagers to ease out muscle soreness, to quiet zones for meditation and relaxation, we provide everything you need to take the time out to restore balance.",
      image: recoveryImage,
    },
  ];

  return (
    <div className="bg-[#141414] text-white px-[12.125rem] pt-[11.125rem] pb-[11.875rem] grid grid-cols-1 md:grid-cols-3 gap-[2rem]">
      {facilities.map((facility, index) => (
        <div
          key={index}
          className="group relative overflow-hidden flex flex-col"
        >
          <div className="w-full h-[30vw] max-h-[400px] flex-shrink-0">
            <img
              src={facility.image}
              alt={facility.title}
              className="w-full h-full object-cover"
            />
            {/* {facility.video && (
              <video
                autoPlay
                loop
                muted
                className="absolute top-0 left-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                src={facility.video}
              ></video>
            )} */}
          </div>
          <div className="mt-[2rem] font-[400] flex-grow">
            <h3 className="text-[1.5rem] leading-[2rem] text-lg mb-[2rem]">
              {facility.title}
            </h3>
            <p className="text-[1rem] leading-[1.3rem]">
              {facility.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FacilitiesGallery;
