import React, { useEffect, useRef } from "react";

const TextScroller: React.FC = () => {
  const scrollerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scroller = scrollerRef.current;
    if (scroller) {
      const scrollSpeed = 1;

      const scroll = () => {
        scroller.scrollLeft += scrollSpeed;
        if (scroller.scrollLeft >= scroller.scrollWidth / 2) {
          scroller.scrollLeft = 0;
        }
        requestAnimationFrame(scroll);
      };

      requestAnimationFrame(scroll);
    }
  }, []);

  return (
    <div
      id="text-scroller"
      className="text-scroller-container"
      ref={scrollerRef}
    >
      <div className="text-scroller-content">
        {Array(20)
          .fill(0)
          .map((_, idx) => (
            <React.Fragment key={idx}>
              <span className="scroll-text uppercase">dickson now open</span>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default TextScroller;
