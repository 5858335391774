import React, { useEffect, useState } from "react";
import iceBathImage from "../../../assets/svg/wellness_popup.svg";
import green_logo from "../../../assets/svg/GU_logo_green.svg";

const WellnessPopup = ({ onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Trigger fade-in effect on mount
    setVisible(true);
  }, []);

  const handleClose = () => {
    // Trigger fade-out effect before closing
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, 300); // This timeout matches the transition duration
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
        visible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="bg-[#CADED5] max-w-[65.125rem] max-h-[36.5rem] w-full md:w-[90%] flex flex-col md:flex-row relative overflow-hidden transition-transform duration-300 transform scale-100">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-[#CADED5] text-2xl"
        >
          &times;
        </button>

        {/* Left side content */}
        <div className="flex flex-col md:w-1/2 w-full text-center md:text-left mt-[3.625rem] mb-[4rem] px-[6.25rem] items-center p-6">
          <img
            src={green_logo}
            alt="GU Logo"
            className="mb-[4.688rem] mx-auto md:mx-0"
          />
          <div className="flex flex-col items-center gap-1 mb-[1.75rem]">
            <h2 className="font-[500] text-[#274240] text-[2.375rem] leading-[3.125rem]">
              21 DAY
            </h2>
            <h2 className="font-[500] text-[#274240] text-[2.375rem] leading-[3.125rem]">
              ICE BATH
            </h2>
            <h2 className="font-[500] text-[#274240] text-[2.375rem] leading-[3.125rem]">
              CHALLENGE
            </h2>
          </div>

          <p className="text-[#274240] text-[1.5rem] leading-[1.938rem] font-[400] mb-[4.188rem]">
            20th MAY - 9th JUNE
          </p>
          <button className="bg-[#274240] text-[#CADED5] px-[1.375rem] py-[0.625rem] rounded-full capitalize text-4 font-[400] leading-[1.3rem]">
            Learn More
          </button>
        </div>

        {/* Right side image */}
        <div className="md:w-1/2 w-full h-full overflow-hidden flex-grow">
          <img
            src={iceBathImage}
            alt="Ice Bath Challenge"
            className="w-full h-full object-cover"
            style={{ maxHeight: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default WellnessPopup;
