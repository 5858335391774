import React from "react";

function Title({ title }: { title: string }) {
  return (
    <h3
      className={`text-[#CADEDF] uppercase text-3xl sm:text-4xl font-medium  mb-6`}
    >
      {title}
    </h3>
  );
}

export default Title;
