import React from "react";

import Layout from "../components/layout/Layout";
import useFetch from "../hooks/useFetch";
import VideoContainer from "../components/HomePage/VideoContainer";
import JoinTheJourney from "../components/Wellness/JoinTheJourney/JoinTheJourney";
import TextScroller from "../components/Wellness/TextScroller/TextScroller";
import FacilitiesGallery from "../components/Wellness/Facilities/FacilitiesGallery";
import CommitToWellness from "../components/Wellness/Facilities/CommitToWellness";
import AppSection from "../components/Wellness/AppSection/AppSection";

function WellnessFacilities() {
  const { data } = useFetch({
    params: "home-page",
    populate:
      "introVideo,bannerMobile,bannerURL,step_1.background,step2.background,step3.background,step4.background",
  });
  return (
    <Layout>
      {data && (
        <div className="wellness-home">
          <VideoContainer data={data} />
          <TextScroller />
          <FacilitiesGallery />
          <CommitToWellness />
          <AppSection />
          <TextScroller />
        </div>
      )}
    </Layout>
  );
}

export default WellnessFacilities;
