import React from "react";

import mb1 from "../assets/images/invest-mb1.jpg";
import mb2 from "../assets/images/invest-mb2.jpg";
import mb4 from "../assets/images/invest-mb4.jpg";
import mb5 from "../assets/images/invest-mb5.jpg";
import background from "../assets/images/investors.jpg";
import Layout from "../components/layout/Layout";

function Investors() {
  return (
    <Layout>
      <div className="h-[130px] bg-black w-full"></div>
      <div className="w-full  bg-black">
        {/* For Desktop */}
        <div className="max-w-[1250px] md:block hidden relative mx-auto">
          <img
            src={background}
            alt="investor"
            className="w-full object-cover"
          />
          <a target="_blank" href="https://www3.clublime.com.au/members/?g=asx">
            <div className="absolute left-[55%] cursor-pointer lg:w-[200px] w-[100px] opacity-0 lg:h-[50px] h-[30px] top-[25%]"></div>
          </a>
        </div>
        {/* For Mobile */}
        <div className="md:hidden block">
          <img src={mb1} alt="investor" className="w-full object-cover" />
          <div className="relative">
            <img src={mb2} alt="investor" className="w-full object-cover" />
            <a
              target="_blank"
              href="https://www3.clublime.com.au/members/?g=asx"
            >
              <div className="absolute cursor-pointer opacity-0 sm:bottom-[15%] bottom-[10%] left-[20%] w-[150px] h-[50px] "></div>
            </a>
          </div>
          <img src={mb4} alt="investor" className="w-full object-cover" />
          <img src={mb5} alt="investor" className="w-full object-cover" />
        </div>
      </div>
    </Layout>
  );
}

export default Investors;
