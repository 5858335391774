import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";

function SuspensionForms() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Dynamically load the external Hapana script if necessary after the component mounts
    const script = document.createElement("script");
    script.src = "https://grow-api.hapana.com/js/form_embed.js"; // Adjust the script if this is the required one
    script.async = true;
    document.body.appendChild(script);

    // Set loading to false once the script is loaded
    script.onload = () => {
      setLoading(false);
    };

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (loading) {
    return (
      <div className="loader" id="loader">
        Loading...
      </div>
    ); // Show a loading indicator while loading
  }

  return (
    <Layout>
      <div className="member-page-container timetable-container">
        <div className="grid-x">
          <div className="cell">
            <div className="membership-header">
              <h1 className="h4 pb-[25px]">Suspend Membership</h1>
            </div>
          </div>
          <div className="flex w-[100vw] mx-auto flex-col">
            <div className="w-[100vw] max-w-[1250px] md:px-0 mt-[10px] flex mb-[80px] flex-col mx-auto bg-[#0a0a0a]">
              <div
                id="join"
                className="hapana-container w-[100vw] max-w-[1250px]"
              >
                <iframe
                  src="https://grow-api.hapana.com/widget/form/z6IQYWDzlS54gLyf9Hna"
                  width="100%"
                  height="1750px" // Set a fixed height here
                  frameBorder="0"
                  allowFullScreen
                  title="Hapana Widget"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SuspensionForms;
