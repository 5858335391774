import React from "react";
import { Link } from "gatsby";

function ClassIntro(props: any) {
  const { data } = props

  return (
    <div id="classes" className="about-info-container">
      <div className="grid-container about-info">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-10 about-info-header">
            <h3>
              {data?.data?.attributes?.midBanner?.title}
            </h3>
          </div>
          <div className="cell medium-6">
            <div
              className="bg-image about-img"
              data-interchange="[assets/img/about-classes-new.jpg, small]"
              data-resize="cdq52h-interchange"
              id="cdq52h-interchange"
              style={{ backgroundImage: `url(${data?.data?.attributes?.midBanner?.background?.data?.attributes?.url})` }}
              data-events="resize"
            ></div>
          </div>
          <div className="cell medium-6 about-info-content">
            {data?.data?.attributes?.midBanner?.customMetadata?.map((item: string, index: number) => (
              <p key={index} className="p2">{item}</p>
            ))}
            <div className="classes-btn medium-text-right">
              <Link to="/classes" className="button large transparent">
                Classes
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassIntro;
