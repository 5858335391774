import React, { useState } from "react";
import WellnessOurSpaces from "../../../assets/images/wellness/WellnessOurSpaces.jpg";
import GetInTouchFormSmall from "../../../components/Wellness/Pricing/GetInTouchFormSmall";
import Title from "../../WellnessJoinNow/Title";
import { Link } from "gatsby";

const spaces = [
  {
    name: "Dickson",
    address:
      "South Building, DKSN 2.0 Level 2, 23 Challis St, Dickson ACT 2602",
    phone: "02 6242 3961",
    email: "dickson@groundup.studio",
    offerings: [
      "Traditional Sauna",
      "Infrared Sauna",
      "Ice Bath",
      "Red Light Therapy",
    ],
    image: WellnessOurSpaces,
  },
  // Additional spaces can be added here if needed
];

const OurSpaces: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);

  const handleEnquireNowClick = () => {
    setIsContactFormOpen(true);
  };

  const handleCloseModal = () => {
    setIsContactFormOpen(false);
  };

  return (
    <div className="p-8 md:pl-[195px] md:pr-[282px] pb-[66px] md:pb-[194px] pt-[2.1rem] md:pt-[134px] bg-[#141414]">
      <div className="flex flex-col gap-8">
        {spaces.map((space, index) => (
          <React.Fragment key={index}>
            <Title title="Our Spaces" />

            <div className="flex flex-col md:flex-row gap-[2rem] md:gap-[4.88rem] md:max-h-[443px]">
              <img
                src={space.image}
                alt={space.name}
                className="object-cover w-full md:w-[50%] h-auto max-w-[751px] max-h-[443px]"
              />
              <div className="text-left w-full flex flex-col justify-between ">
                <>
                  <div>
                    <h3 className="text-[24px] font-[600] md:mt-[0.94rem] leading-[32px] wellness-title-text font-semibold mb-[1.25rem] uppercase">
                      {space.name}
                    </h3>
                    <div className="flex flex-col md:flex-row justify-end gap-4">
                      <div className="w-1/2">
                        <p className="mb-2 text-white text-sm">
                          {space.address}
                        </p>
                        <p className="mb-2 text-white text-sm">{space.phone}</p>
                        <p className="mb-4 text-white text-sm">{space.email}</p>
                        <a
                          href="https://www.google.com/maps/place/GROUNDUP+Wellness/@-35.2497381,149.1323666,17z/data=!3m1!4b1!4m6!3m5!1s0x6b16531fb020df39:0xb242928dde0bef76!8m2!3d-35.2497425!4d149.1349469!16s%2Fg%2F11wr1lvwx0?hl=en&entry=ttu&g_ep=EgoyMDI0MTIwMy4wIKXMDSoASAFQAw%3D%3D"
                          className="underline mb-4 inline-block text-white"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Where to find us
                        </a>
                      </div>

                      <div className="mb-8 text-white w-1/2">
                        {space.offerings.map((offering, idx) => (
                          <p key={idx} className="text-sm">
                            + {offering}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-start md:justify-start space-x-4 md:mb-[46px] mt-10">
                    <div>
                      <Link
                        to="#download"
                        className="px-4 py-2 bg-[#CADED5] hover:bg-[#274240] text-[#274240] hover:text-[#CADED5] rounded-full font-[400] uppercase text-4"
                      >
                        Join now
                      </Link>
                    </div>
                    <div className="btn-container text-center ">
                      <Link
                        to={`/sale-lead-form-dickson`}
                        className="px-4 py-2 bg-transparent hover:bg-[#CADED5] text-[#CADED5] hover:text-[#274240] border border-[#CADED5] rounded-full font-[400] uppercase text-4"
                      >
                        Enquire
                      </Link>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default OurSpaces;
